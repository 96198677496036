@use "sass:map";
@use "@angular/material" as mat;
@use "@ng-matero/extensions" as mtx;

@include mat.core();

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

// Fix for invisible flag: https://github.com/tanansatpal/ngx-mat-intl-tel-input/issues/185
.ngx-mat-tel-input-container {
  .mat-mdc-menu-trigger {
    opacity: 1 !important;
  }
}

$dark-primary-text: rgba(#383838, 0.87);
$dark-secondary-text: rgba(#383838, 0.54);
$dark-disabled-text: rgba(#383838, 0.38);
$dark-dividers: rgba(#383838, 0.12);
$dark-focused: rgba(#383838, 0.12);
$light-primary-text: #ffeadb;
$light-secondary-text: rgba(#ffeadb, 0.7);
$light-disabled-text: rgba(#ffeadb, 0.5);
$light-dividers: rgba(#ffeadb, 0.12);
$light-focused: rgba(#ffeadb, 0.12);

$pvg-pink-palette: (
  50: #fce4ec,
  100: #f8bbd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #d96caa, // This should be the primary color
  600: #d81b60,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  A100: #ff80ab,
  A200: #ff4081,
  A400: #f50057,
  A700: #c51162,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$pvg-blue-palette: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #2196f3,
  600: #1e88e5,
  700: #2ca4e0, // This should be the secondary color
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$pvg-dark-grey-palette: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #383838, // This should be the tertiary color
  600: #1e88e5,
  700: #0a63b5,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$pvg-background-palette: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #f1ded2, // This should be the app background color
  400: #ffeadb, // This should be the component background color
  500: #383838,
  600: #1e88e5,
  700: #0a63b5,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$light-primary-color: mat.m2-define-palette($pvg-pink-palette, 500);
$light-accent-color: mat.m2-define-palette($pvg-blue-palette, 700);
$light-warn-color: mat.m2-define-palette(mat.$m2-orange-palette, 900);
$light-background-color: mat.m2-define-palette($pvg-background-palette, 400);

@font-face {
  font-family: "PeaceSansBold";
  src: url("assets/fonts/Peace-Sans-Webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  letter-spacing: 1.1;
}

$pvg-typography: mat.m2-define-typography-config(
  $font-family: "PeaceSansBold",
);

$pvg-light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $light-primary-color,
      accent: $light-accent-color,
      warn: $light-warn-color,
    ),
    density: 0,
  )
);

$pvg-light-theme-with-peace-typography: mat.m2-define-light-theme(
  (
    color: (
      primary: $light-primary-color,
      accent: $light-accent-color,
      warn: $light-warn-color,
    ),
    density: 0,
    typography: $pvg-typography,
  )
);

// TODO: Component background colors needs to be: pvg-background-palette 400
// More information: https://github.com/angular/components/issues/24726

.mat-app-background,
mat-sidenav-container {
  background: mat.m2-get-color-from-palette(
    $pvg-background-palette,
    300
  ) !important;
  --mat-app-background: mat.m2-get-color-from-palette(
    $pvg-background-palette,
    300
  ) !important;
}

.mat-component-background {
  background: mat.m2-get-color-from-palette(
    $pvg-background-palette,
    400
  ) !important;
}

// Include the theme mixins for other components you use here.
// See here for all the different components you can theme:
// https://github.com/angular/components/blob/main/src/material/core/theming/_all-theme.scss 
@include mat.core-theme($pvg-light-theme);
@include mat.autocomplete-theme($pvg-light-theme);
@include mat.badge-theme($pvg-light-theme);
@include mat.bottom-sheet-theme($pvg-light-theme);
@include mat.button-theme($pvg-light-theme);
@include mat.icon-button-theme($pvg-light-theme);
@include mat.fab-theme($pvg-light-theme);
@include mat.button-toggle-theme($pvg-light-theme);
@include mat.card-theme($pvg-light-theme-with-peace-typography);
@include mat.checkbox-theme($pvg-light-theme);
@include mat.chips-theme($pvg-light-theme);
@include mat.table-theme($pvg-light-theme);
@include mat.datepicker-theme($pvg-light-theme);
@include mat.dialog-theme($pvg-light-theme);
@include mat.divider-theme($pvg-light-theme);
@include mat.expansion-theme($pvg-light-theme);
@include mat.grid-list-theme($pvg-light-theme);
@include mat.icon-theme($pvg-light-theme);
@include mat.input-theme($pvg-light-theme);
@include mat.list-theme($pvg-light-theme);
@include mat.menu-theme($pvg-light-theme);
@include mat.paginator-theme($pvg-light-theme);
@include mat.progress-bar-theme($pvg-light-theme);
@include mat.progress-spinner-theme($pvg-light-theme);
@include mat.radio-theme($pvg-light-theme);
@include mat.select-theme($pvg-light-theme);
@include mat.sidenav-theme($pvg-light-theme);
@include mat.slide-toggle-theme($pvg-light-theme);
@include mat.slider-theme($pvg-light-theme);
@include mat.stepper-theme($pvg-light-theme);
@include mat.sort-theme($pvg-light-theme);
@include mat.tabs-theme($pvg-light-theme-with-peace-typography);
@include mat.toolbar-theme($pvg-light-theme);
@include mat.tooltip-theme($pvg-light-theme);
@include mat.tree-theme($pvg-light-theme);
@include mat.snack-bar-theme($pvg-light-theme);
@include mat.form-field-theme($pvg-light-theme);

// Mateo Datetime picker theming.
@include mtx.all-component-themes($pvg-light-theme);
// TODO: Sidenav active menu item loses color when user clicks on the page.
// TODO: Text color is not picked up
// More information: https://github.com/angular/components/issues/13084
.mat-mdc-list-item:focus,
.mat-list-option:focus,
.list-item-active {
  color: $light-primary-text !important;
  background-color: mat.m2-get-color-from-palette($pvg-pink-palette, 500) !important;
}

.mdc-list-item.mdc-list-item--activated::before {
  background: mat.m2-get-color-from-palette($pvg-pink-palette, 500);
}

// Mat Button Toggle
.mat-button-toggle {
  background-color: mat.m2-get-color-from-palette(
    $pvg-background-palette,
    300);
}

.mat-button-toggle-checked {
  background-color: mat.m2-get-color-from-palette($pvg-pink-palette, 500);
}

// WORKAROUND: Fix elevation for mat-cards
// See https://github.com/angular/components/issues/26094
@for $i from 0 through 24 {
  .mat-mdc-card.mat-elevation-z#{$i} {
    @include mat.elevation($i);
  }
}
